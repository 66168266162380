
<template>
    <div>
      <div v-if="state.type === '' || showLabel" :class="{ 'is-invalid': error }">
        <div class="drop-container d-flex cursor-pointer">
          <span id='button' class="d-flex align-items-center justify-content-center" @click="chooseFile">{{ $t('t-select-file') }}</span>
          <input id="chooseFile" class="input-drop" :accept="accept" :multiple="multiple" @change="onChange" ref="inputDrop" type='file'>
          <span id='val' class="h-100 align-items-center d-flex" style="flex: 1">{{ state.nameFile
          }}</span>
        </div>
      </div>
      <div v-if="state.type === 'drop-file'" @dragenter.prevent="toggleActive" @dragleave.prevent="toggleActive" @dragover.prevent
        @drop.prevent="toggleActive" :class="{ 'active-dropzone': active, 'is-invalid': error }"
        class="dropzone position-relative cursor-pointer">
        <div class="mb-1">
          <i class="display-4 text-muted ri-upload-cloud-2-fill"></i>
        </div>

        <h5 class="text-center">Drop files here or click to upload.</h5>
        <label for="dropzoneFile" class="bg-light text-dark stretched-link">Upload</label>
        <input :disabled="disabled" :accept="accept" :multiple="multiple" type="file" id="dropzoneFile" @change="onChange"
          class="dropzoneFile btn btn-primary" />

      </div>

      <div v-if="error" class="error-msg invalid-feedback text-center">
        {{ error }}
      </div>
    </div>
  </template>


  <script>
  import { onMounted, reactive, ref, watch } from "vue";
  import i18n from '@/i18n'

  export default {
    name: "dropzone",
    props: {
      propNameFile: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "drop-file",
      },
      accept: {
        type: String,
        default: 'image/*'
      },
      multiple: {
        type: Boolean,
        default: false
      },
      allowedExtensions: {
        type: Array,
        default: () => ['image/jpeg', 'image/png', 'image/jpg']
      },
      maxFileSize: {
        type: Number,
        default: 10
      },
      showLabel: {
        type: Boolean,
        default: false
      }
    },
    setup(props, { emit }) {
      const active = ref(false);
      const state = reactive({
        nameFile: '',
        type: 'drop-file'
      })

      onMounted(() => {
        state.nameFile = props.propNameFile !== '' ? props.propNameFile : '';
        state.type = props.type
      })

      watch(() => props.propNameFile, (value) => {
        state.nameFile = value
      })

      watch(() => props.type, (value) => {
        state.type = value
      })

      const toggleActive = async (e) => {
        if (props.disabled) {
          return;
        }
        active.value = !active.value;
        const file = e.dataTransfer.files[0];
        if(!file) {
            return;
        }
        const check = await validateFile(file);
        if (check) {
          emit('selectedFile', file);
          state.type = ''
        } else {
            state.type = 'drop-file';
            emit('clearFile')
        }
      };

      const error = ref('');

      const validateFile = async (file) => {

        const { allowedExtensions } = props;

        if (file.size / 1024 / 1024 > props.maxFileSize) {
          error.value = i18n.global.t('t-err-upload-max', { value: props.maxFileSize })
          return false;
        }
        if (allowedExtensions.length > 0 && !allowedExtensions.includes(file.type)) {
          error.value = i18n.global.t('msg.allowUpload', {filenames: file.name})
          return false;
        }
        error.value = '';
        return true;
      }

      const onChange = async (e) => {
        if (props.disabled) {
          return;
        }
        const files = e.target.files || e.dataTransfer.files;
        const file = files[0] || null;

        state.nameFile = file.name;
        const check = await validateFile(file);
        if (check) {
          state.type = '';
          emit('selectedFile', file);
        } else {
            state.type = 'drop-file';
            emit('clearFile')
        }
      }

      return { active, error, toggleActive, onChange, state };
    },
    methods: {
      chooseFile() {
        this.$refs.inputDrop.click();
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .input-drop {
    height: 38px;
    opacity: 0;
    display: none;
  }

  .drop-container {
    border-radius: 4px;
    height: 38px;
    background-color: white;
    box-shadow: 1px 2px 3px #ededed;
    position: relative;
    border: 1px solid #d8d8d8;
  }

  #val {
    height: 25px;
    font-size: 13px;
    line-height: 38px;
    text-indent: 10px;
    pointer-events: none;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  #button {
    width: 107px;
    cursor: pointer;
    display: block;
    background-color: #f0f2f6;
    color: black;
    font-size: 13px;
    line-height: 25px;
    text-align: center;
    -webkit-transition: 500ms all;
    -moz-transition: 500ms all;
    transition: 500ms all;
  }

  .error-msg {
    white-space: pre-line !important;
  }
  </style>
